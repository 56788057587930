module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"federicogiaconeportfolio","accessToken":"MC5XeUdKWUNRQUFLY0pSUXVq.IEnvv71DMzRg77-9ezsp77-9K1Pvv70377-977-977-9ETnvv73vv70677-977-977-9aRvvv70oSg","schemas":{"page":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1","label":"Titolo","placeholder":"Inserire il titolo di questa pagina"}},"uid":{"type":"UID","config":{"label":"Link","placeholder":"Inserire la parte finale del link di questa pagina"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Contenuto","placeholder":"Inserire il contenuto  di questa pagina"}}},"SEO":{"seo_title":{"type":"Text","config":{"label":"SEO - Title","placeholder":"Inserisci qui il title di questa pagina"}},"seo_description":{"type":"Text","config":{"label":"SEO - Description","placeholder":"Inserisci qui la description di questa pagina"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MQ9S4XK","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Federico Giacone","short_name":"Portfolio","start_url":"/?utm_source=PWA&utm_medium=HomeIcon","background_color":"#f5f5f5","theme_color":"#161616","display":"fullscreen","orientation":"portrait","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c64b7a331b750c7dab92d5ca33ae46ba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
